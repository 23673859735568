.budget-table {
  overflow-y: scroll;
}

.category {
  background-color: var(--color-bg-secondary);
  font-weight: 600;

  &:hover {
    .add-button {
      opacity: 1;
    }
  }
}

td:nth-child(1),
td:nth-child(2),
th:nth-child(1),
th:nth-child(2) {
  text-align: left;
}

td:nth-child(6),
th:nth-child(6) {
  text-align: center;
}

td:nth-child(3),
td:nth-child(4),
td:nth-child(5),
th:nth-child(3),
th:nth-child(4),
th:nth-child(5) {
  text-align: right;
  font-family: monospace;
}

td:nth-child(1),
td:nth-child(7) {
  padding-left: 0.5rem;
  width: 30px;
}

tr {
  height: 3em !important;
}

.add-button {
  display: flex;
  align-self: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}
