.date-selector {
  display: flex;
  position: relative;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  margin: 10px;
}

.date-button {
  padding: 8px 12px;
  background-color: #f5f5f5; /* Soft gray background */
  border: 1px solid #dcdcdc; /* Light border color */
  color: #333;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;

  &.prev {
    border-radius: 5px 0 0 5px;
  }

  &.next {
    border-radius: 0 5px 5px 0;
  }

  &:hover {
    background-color: #e0e0e0; /* Slightly darker gray for hover */
  }

  &:focus {
    outline: none;
    background-color: #e0e0e0;
  }
}

.date-input {
  padding: 10px;
  width: 120px; /* Adjust as necessary */
  background-color: #f5f5f5; /* Soft gray background */
  border: 1px solid #dcdcdc;
  border-left: none;
  border-right: none;
  text-align: center;
  color: #333;
  font-size: 16px;
  cursor: pointer;
  user-select: none;
}

.calendar-popup {
  position: absolute;
  top: 55px; /* Adjust this as needed based on your layout */
  left: 0;
  z-index: 999;
  background-color: #fff;
  border: 1px solid #e0e0e0; /* Border to match design */
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: auto;
}

/* Customize the react-datepicker component */
.react-datepicker {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  border: none;
}

.react-datepicker__header {
  background-color: #f5f5f5;
  border-bottom: 1px solid #dcdcdc;
  border-radius: 8px 8px 0 0;
}

.react-datepicker__month-container {
  width: auto;
}

.react-datepicker__current-month {
  font-weight: 600;
  font-size: 16px;
  color: #333;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  background-color: hsl(41, 74%, 63%); /* A nice green that matches the Assign button */
  color: white;
  border-radius: 6px;
}

.react-datepicker__day--today {
  font-weight: bold;
  color: hsl(41, 74%, 63%);
}

.react-datepicker__month-text--selected {
  background-color: hsl(41, 74%, 63%);
  color: white;
  border-radius: 6px;
}

.react-datepicker__navigation {
  top: 10px;
}

.react-datepicker__navigation-icon {
  margin-top: -3px;
}

/* Navigation button styles to match the design */
.react-datepicker__navigation--previous {
  border-right-color: #333;
}

.react-datepicker__navigation--next {
  border-left-color: #333;
}
